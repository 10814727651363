import {
  deleteAppointment,
  formattedDateString,
  formattedDateTimeString,
  Project,
  Quarter,
  updateAppointment,
  useAbilities,
  useAuth,
  convertToUTCForBackend,
  deleteReminder,
  Response,
  Reminder,
  parseUTC,
} from '@aposphaere/core-kit'
import {
  Button,
  ButtonKind,
  Checkbox,
  DeleteIcon,
  EditIcon,
  IconButton,
  IconButtonKind,
  InputLabel,
  InputMessage,
  InputMessageKind,
  Modal,
  ModalKind,
  toast,
  TextAreaInput,
  TextInput,
} from '@aposphaere/ui-components'
import { Form, Formik } from 'formik'
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react'
import * as Yup from 'yup'
import CrmCalendar from '../CrmCalendar'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import 'react-day-picker/lib/style.css'

import { useAuthenticatedMutation } from '../../hooks/useAuthenticatedMutation'
import InputWithValidation from '../TexInputHOC/TexInputWithValidation'
import TerminationReasonModal from './TerminationReasonModal'
import { timeSchema } from '../../utils/validationSchemas'
import {
  useAppointmentQuery,
  useAppointmentsQuery,
  useAppointmentTypesQuery,
  useOfftimesQuery,
  usePharmacyQuery,
  useProjectsQuery,
  useQuartersQuery,
  useStatusesQuery,
  useTasksQuery,
  useCancelledStatusesIds,
} from '../../hooks/graphql'
import { addDays, format, isAfter, isBefore, isFuture, isSameDay, isSameHour, isWithinInterval, parse, addSeconds } from 'date-fns'
import { useModal, ModalKind as AppModalKind } from '../../contexts/modalContext'
import { CalendarTimeSlot } from '../CrmCalendar/useCalendarData'
import { useAppointmentCreation } from '../../hooks/useAppointmentCreation'
import { TRAINING_COMPLETED } from '../../constants'
import { useCrmContext } from '../../contexts/crmContext'
import { getCurrentProjects } from '../../utils/projects'
import CalendarPanelWrapper from '../CalendarPanelAppointment/CalendarPanelContext'
import { getDurationPerTraining } from '../CustomRouteModal/utils'

/* German localisation  */
const MONTHS = ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember']
const WEEKDAYS_LONG = ['Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag', 'Sonntag']
const WEEKDAYS_SHORT = ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa']
const TIME_IS_OCCUPIED_MESSAGE = 'Diese Zeit ist bereits belegt'
const TIME_HAS_A_HOLIDAY_MESSAGE = 'Der ausgewählte Tag ist ein Feiertag'

type AppointmentFormValues = {
  pharmacyId?: number
  name: string
  salutation: string
  contactPerson: string
  appointmentTypeId: string
  statusId: string
  note: string
  sendConfirmation: boolean
  customEmail?: string
  cancellation_reason?: string
}

type OrderDetailsItem = {
  project_id: string
  amount: number
  length_in_seconds: number
}

/* Hold all selected project Items */

type Props =
  | {
      appointmentId: number
      onClose: () => void
    }
  | {
      pharmacyId: number
      onClose: () => void
    }

const AppointmentModal = ({ onClose, ...props }: Props) => {
  const appointmentId = 'appointmentId' in props ? props.appointmentId : undefined
  const pharmacyId = 'pharmacyId' in props ? props.pharmacyId : undefined

  const { data: appointment } = useAppointmentQuery(appointmentId)
  const { data: tasks } = useTasksQuery()
  const tasksQuery = useTasksQuery()
  const { data: activePharmacy, refetch: refetchPharmacy } = usePharmacyQuery(pharmacyId || appointment?.pharmacy?.id)
  const { openModal } = useModal()
  const cancelledStatusIds = useCancelledStatusesIds()

  const appointmentTypesQuery = useAppointmentTypesQuery()
  const { data: offtimes } = useOfftimesQuery()
  const { data: projects } = useProjectsQuery()
  const { data: statuses } = useStatusesQuery()
  const { data: quarters } = useQuartersQuery()
  const { activeCluster } = useCrmContext()
  const agentStateHolidays = useMemo(() => activeCluster?.agent?.address?.federal_state?.holidays || [], [
    activeCluster?.agent?.address?.federal_state?.holidays,
  ])

  const appointmentsQuery = useAppointmentsQuery()
  const appointments = appointmentsQuery.data
  const deleteNoteMutation = useAuthenticatedMutation(deleteReminder)

  const auth = useAuth()
  const { delete_appointments } = useAbilities()
  const [endTimeLabel, setEndTimeLabel] = useState<string>('')
  const [isCustomEmail, setIsCustomEmail] = useState<boolean>(false)

  const createAppointmentMutation = useAppointmentCreation()
  const updateAppointmentMutation = useAuthenticatedMutation(updateAppointment)
  const deleteAppointmentMutation = useAuthenticatedMutation(deleteAppointment)

  const activePharmacyId = activePharmacy?.id

  const [title, setTitle] = useState<string>('Neuer Termin')
  const [displayCalendar, setDisplayCalendar] = useState<boolean>(false)
  const [selectedTimeslot, setSelectedTimeSlot] = useState<Date | undefined>(undefined)
  const [selectedTimeSlotManual, setSelectedTimeSlotManual] = useState<Date | undefined>(undefined)
  const [selectedDaySlotManual, setSelectedDaySlotManual] = useState<Date | undefined>(undefined)
  const [timeSlotEntryInvalid, setTimeSlotEntryInvalid] = useState<boolean | undefined>(undefined)
  const [selectedOrderItems, setSelectedOrderItems] = useState<OrderDetailsItem[]>([])
  const [isOccupied, setIsOccupied] = useState<boolean>(false)
  const [isTerminationReasonModalOpen, setTerminationReasonModalOpen] = useState(false)
  const validQuarters = useMemo<Quarter[]>(() => {
    if (quarters) {
      return quarters.filter((quarter) => quarter?.to && isFuture(parseUTC(quarter.to)))
    }
    return []
  }, [quarters])

  const endDate = useMemo(() => new Date(Math.max(...validQuarters.map((quarter) => (quarter?.to ? parseUTC(quarter.to).getTime() : 0)))), [
    validQuarters,
  ])
  const isValidEmail = useCallback((email) => {
    const emailPattern = /^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,4}$/
    return emailPattern.test(email || '')
  }, [])

  const DEFAULT_TIME_PER_PROJECT = 1800

  useLayoutEffect(() => {
    if (appointment) {
      setTitle('Termin aktualisieren')
    }
  }, [appointment])

  useEffect(() => {
    if (appointment && projects) {
      const parsedDate = parse(appointment.date!, 'yyyy-MM-dd HH:mm:ss', new Date())
      const formattedDate = format(parsedDate, "EEE MMM dd yyyy HH:mm:ss 'GMT'xxx", { useAdditionalWeekYearTokens: false })
      const formDate = new Date(formattedDate)

      setSelectedTimeSlot(appointment.date ? formDate : undefined)
      const orderItems: OrderDetailsItem[] = appointment.order_items
        ?.filter((each) => each.project?.is_orderable)
        .map((item) => ({
          amount: item.amount,
          project_id: `${item.project_id}`,
          length_in_seconds: projects?.find((project) => `${project.id}` === `${item.project_id}`)?.booked_time_in_seconds || DEFAULT_TIME_PER_PROJECT,
        }))
      setSelectedOrderItems(orderItems)
    } else {
      setSelectedTimeSlot(undefined)
    }
  }, [appointment, projects])

  const appointmentStauses = statuses?.forAppointments || []

  const initialStatusId = String(appointment?.status?.id || appointment?.status_id || '')
  const initialAppointmentTypeId = String(appointment?.appointmentType?.id || appointmentTypesQuery?.data?.appointmentTypes?.[0].id || '')

  const intialFormValues: AppointmentFormValues = {
    pharmacyId: activePharmacy ? activePharmacyId : undefined,
    name: activePharmacy ? activePharmacy.name || '' : '',
    cancellation_reason: appointment?.cancellation_reason ? appointment?.cancellation_reason : '',
    salutation: 'Frau',
    contactPerson: '',
    appointmentTypeId: initialAppointmentTypeId,
    statusId: initialStatusId,
    note: appointment?.note || '',
    sendConfirmation: isValidEmail(activePharmacy?.email),
  }

  const AppointmentValidationScheme = Yup.object().shape({
    contactPerson: Yup.string(),
    salutation: Yup.string().min(1, 'Zu kurz').required('Pflichtfeld'),
    appointmentTypeId: Yup.string().required('Bitte auswählen'),
    statusId: Yup.string().required('Bitte auswählen'),
    cancellation_reason: Yup.string().when('statusId', {
      is: (statusId) => statusId === '12',
      then: Yup.string().required('Pflichtfeld'),
    }),
    customEmail: isCustomEmail ? Yup.string().email().required('Pflichtfeld') : Yup.string(),
  })

  const onFormSubmit = async (values: AppointmentFormValues) => {
    if (!selectedTimeslot) {
      setTimeSlotEntryInvalid(true)
    } else {
      setTimeSlotEntryInvalid(false)
    }

    let date: string | undefined

    if (selectedTimeslot !== undefined) {
      date = format(selectedTimeslot.getTime(), "yyyy-MM-dd'T'HH:mm:ss'.000Z'")
    }

    const appointmentVariables = {
      pharmacy_id: activePharmacy?.id,
      quarter_id: selectedQuarter?.id || appointment?.quarter?.id,
      status_id: values.statusId,
      appointment_type_id: values.appointmentTypeId,
      creator_id: auth.user?.id,
      //date: convertToUTCForBackend(selectedTimeslot),
      date: date,
      note: values.note,
      order_items: selectedOrderItems.map((item) => ({
        project_id: item.project_id,
        amount: Number(item.amount),
      })),
      cancellation_reason: values.cancellation_reason,
      id: appointment?.id,
      send_confirmation: values.sendConfirmation,
      confirmation_email_address: isCustomEmail ? values.customEmail : '',
    }
    try {
      const createOrEdit = appointment ? updateAppointmentMutation(appointmentVariables) : createAppointmentMutation(appointmentVariables)
      await createOrEdit
      toast.show({
        type: 'success',
        headline: appointment ? 'Termin erfolgreich editiert' : 'Termin erfolgreich angelegt',
      })

      const pharmacyTasks: Reminder[] = tasks?.filter((reminder) => reminder.pharmacy?.id === pharmacyId) || []

      const getMutation = (reminder: Reminder) => {
        let mutation: (() => Promise<Response<unknown>>) | null = null
        mutation = () => deleteNoteMutation({ id: reminder.id })
        return mutation
      }

      const onDeleteReminder = async (reminder: Reminder) => {
        const adjustedValues = {
          ...reminder,
          until: convertToUTCForBackend(reminder.until) ?? undefined,
        }
        const mutation = getMutation(adjustedValues)
        const reminderResponse = await mutation()
        await tasksQuery.refetch()
        if (reminderResponse?.errors !== undefined) {
          alert(reminderResponse.errors)
          return
        }
      }

      const deleteReminders = async () => {
        for (const reminder of pharmacyTasks || []) {
          await onDeleteReminder(reminder)
        }
      }

      deleteReminders()
      
      void refetchPharmacy()
      void appointmentsQuery.refetch()
      onClose()
      if (cancelledStatusIds.includes(values.statusId)) {
        openModal({
          kind: AppModalKind.AppointmentCancel,
          pharmacyId: activePharmacy?.id,
          date: selectedTimeslot,
          order_items: selectedOrderItems.map((item) => ({
            project_id: item.project_id,
            amount: Number(item.amount),
          })),
        })
      }
    } catch (err) {
      toast.show({
        headline: 'Zu dieser Zeit ist kein Termin möglich.',
        type: 'error',
      })
    }
  }

  const onAppointmentDeleteBtnClick = () => {
    setTerminationReasonModalOpen(true)
  }

  const handleDeleteAppointment = async (terminationReason: string) => {
    const response = await deleteAppointmentMutation({
      id: appointment?.id,
      terminationReason: terminationReason,
    })
    if (response.errors !== undefined) {
      // eslint-disable-next-line no-console
      console.warn(response.errors)
      return
    }
    void appointmentsQuery.refetch()
    onClose()
    toast.show({
      headline: 'Termin erfolgreich gelöscht',
      type: 'success',
    })
  }

  const handleTerminationReasonConfirm = async (terminationReason: string) => {
    await handleDeleteAppointment(terminationReason)
    void refetchPharmacy()
  }

  const onOpenCalendar = () => {
    setTitle('Datum und Uhrzeit festlegen')
    setDisplayCalendar(true)
  }

  const onCloseCalendar = () => {
    setTitle('Neuer Termin')
    setDisplayCalendar(false)
  }

  /* Convert seconds into hours and minutes */
  const calculateTotalAppointmentLength = useCallback(() => {
    if (selectedTimeslot) {
      // nothing is selected
      const actualOrders = selectedOrderItems.filter((orderItem) => orderItem.amount > 0)

      if (actualOrders.length === 0) {
        setEndTimeLabel('')
        return
      }

      const endTime = new Date(selectedTimeslot)
      selectedOrderItems.forEach((orderItem) => {
        if (orderItem.amount > 0) {
          endTime.setSeconds(endTime.getSeconds() + orderItem.length_in_seconds)
        }
      })

      const parsedDate = parse(appointment?.date!, 'yyyy-MM-dd HH:mm:ss', new Date())
      const formattedDate = format(parsedDate, "EEE MMM dd yyyy HH:mm:ss 'GMT'xxx", { useAdditionalWeekYearTokens: false })
      const formDate = new Date(formattedDate)
      const appointmentStartDate = formDate
      const trainingDurationInSeconds = getDurationPerTraining(appointment ? appointment.order_items : [], projects ?? [])
      const appointmentEndDate = addSeconds(selectedTimeslot, trainingDurationInSeconds)
      setEndTimeLabel(' bis ' + format(appointmentEndDate, 'HH:mm'))
    }
  }, [selectedOrderItems, selectedTimeslot, appointment?.end_date])

  useEffect(() => calculateTotalAppointmentLength(), [calculateTotalAppointmentLength])
  /* OrderItem method to get Project details */
  const updateOrderItems = (amount: number, projectid: string, project: Project) => {
    const updatedOrderItems: OrderDetailsItem[] = Object.assign([], selectedOrderItems)
    // check if item is already in list
    const itemExists = selectedOrderItems.filter((item) => item.project_id === projectid)

    if (itemExists.length > 0) {
      updatedOrderItems.forEach((item) => {
        if (item.project_id === projectid) {
          item.amount = amount
        }
      })
      setSelectedOrderItems(updatedOrderItems)
      return
    }

    const orderItem: OrderDetailsItem = {
      project_id: projectid,
      amount,
      length_in_seconds: project.booked_time_in_seconds,
    }
    updatedOrderItems.push(orderItem)
    setSelectedOrderItems(updatedOrderItems)
  }

  const selectedQuarter = selectedTimeslot
    ? validQuarters.find((quarter) => {
        if (!quarter.to || !quarter.from) {
          return false
        }
        return (
          isAfter(selectedTimeslot, parseUTC(quarter.from)) &&
          (isBefore(selectedTimeslot, parseUTC(quarter.to)) || isSameDay(selectedTimeslot, parseUTC(quarter.to)))
        )
      })
    : null

  useEffect(() => {
    if (appointment?.date) {
      const parsedDate = parse(appointment.date!, 'yyyy-MM-dd HH:mm:ss', new Date())
      const formattedDate = format(parsedDate, "EEE MMM dd yyyy HH:mm:ss 'GMT'xxx", { useAdditionalWeekYearTokens: false })
      const formDate = new Date(formattedDate)

      setSelectedTimeSlot(appointment.date ? formDate : undefined)
    }
  }, [appointment])

  const onTimeSlotSelected = useCallback((date: Date, fromCalendar = true) => {
    if (fromCalendar) {
      setSelectedTimeSlot(date)
      onCloseCalendar()
    } else {
      setSelectedDaySlotManual(date)
    }
  }, [])

  const disabledChangeStatus = (values: AppointmentFormValues) => (values.statusId === '3' ? true : false)

  const availableStatuses =
    useMemo(() => {
      const appointmentStates = statuses?.forAppointments?.map((status) => ({
        ...status,
        disabled: status.label?.toLowerCase() === TRAINING_COMPLETED,
      }))

      return appointmentStates?.filter((status) => status.is_active)
    }, [statuses]) ?? []
  /* OnSelect to get Selected Projects with Amount and Id */

  const getAvailableProjects = (): Project[] =>
    // only show projects in the future that are currently active
    getCurrentProjects(projects, appointment?.date ? parseUTC(appointment.date) : new Date())

  const [selected, setSelected] = React.useState('slot')

  const handleInputChange = (e: { target: { value: React.SetStateAction<string> } }) => {
    setSelected(e.target.value)
  }

  const unavailableTimes: string[] = useMemo(() => {
    if (!selectedQuarter?.from || !selectedQuarter?.to) {
      return []
    }
    const filteredAppointments =
      appointments?.filter(
        (_appointment) =>
          _appointment.id !== appointment?.id &&
          _appointment?.date &&
          selectedQuarter?.from &&
          selectedQuarter?.to &&
          isWithinInterval(parseUTC(_appointment.date), { start: parseUTC(selectedQuarter.from), end: parseUTC(selectedQuarter.to) }),
      ) || []
    return filteredAppointments.map((_appointment) => _appointment.date || '')
  }, [appointments, appointment, selectedQuarter])

  const onChangeTimeCheck = useCallback((value: string) => !value.match(/(?!:)\D/g) || value === '', [])

  const onTimeslotClick = useCallback(
    (slot: CalendarTimeSlot) => {
      if (slot.type === 'free') {
        onTimeSlotSelected(slot.date)
      }
      if (slot.type === 'offtime') {
        openModal({ kind: AppModalKind.OfftimesDelete, offtimes: [slot.data] })
      }
    },
    [onTimeSlotSelected, openModal],
  )

  const validateAndSetTime = useCallback(
    (value: string, schema: boolean): string | void => {
      const clearManualTimeslot = () => setSelectedTimeSlotManual(undefined)
      setIsOccupied(false)
      if (!schema) {
        clearManualTimeslot()
        return `Verwenden Sie das Format HH:MM`
      }

      if (!value) {
        return
      }

      const [hours, minutes] = value.split(':')
      const date = selectedDaySlotManual || new Date()
      date.setHours(parseInt(hours, 10))
      date.setMinutes(parseInt(minutes, 10))

      // Check state holidays
      const hasHolidayOnGivenDay = !!agentStateHolidays.find((holiday) => holiday?.date && isSameDay(new Date(holiday.date), date))
      if (hasHolidayOnGivenDay) {
        setIsOccupied(true)
        return TIME_HAS_A_HOLIDAY_MESSAGE
      }

      // // check available time with another appointments
      // const filteredUnavailable = unavailableTimes.filter((time) => isSameHour(date, parseUTC(time)))
      // if (filteredUnavailable.length) {
      //   const dateMiliSec = Date.parse(date.toISOString())
      //   if (appointment?.date) {
      //     const appointmentTimeMiliSec = Date.parse(appointment.date)
      //     if (dateMiliSec === appointmentTimeMiliSec) {
      //       setIsOccupied(false)
      //       return
      //     }
      //   }
      //   setIsOccupied(true)
      //   return TIME_IS_OCCUPIED_MESSAGE
      // }

      // check available time with offtimes
      if (
        offtimes?.filter((offtime) => {
          if (!offtime?.date) {
            return false
          }
          if (offtime.whole_day) {
            return isSameDay(date, parseUTC(offtime.date))
          }
          return isSameHour(date, parseUTC(offtime.date))
        }).length
      ) {
        clearManualTimeslot()
        return 'Unmöglich zu diesem Zeitpunkt'
      }
      // set value if everything clear
      setSelectedTimeSlotManual(date)
    },
    [selectedDaySlotManual, agentStateHolidays, unavailableTimes, offtimes, appointment?.date],
  )
  useEffect(() => {
    if (!displayCalendar) {
      setSelectedDaySlotManual(undefined)
    }
  }, [displayCalendar])

  useEffect(() => {
    if (appointment && displayCalendar) {
      const parsedDate = parse(appointment.date!, 'yyyy-MM-dd HH:mm:ss', new Date())
      const formattedDate = format(parsedDate, "EEE MMM dd yyyy HH:mm:ss 'GMT'xxx", { useAdditionalWeekYearTokens: false })
      const formDate = new Date(formattedDate)

      const date = formDate
      onTimeSlotSelected(date, false)
    }
  }, [appointment, onTimeSlotSelected, displayCalendar])

  const handleOnDateAndTimeFocus = useCallback(() => {
    setSelected('manual')
  }, [])

  const tomorrow = addDays(new Date(), 1)
  return (
    <>
      <Modal
        kind={ModalKind.md}
        title={title}
        onClose={onClose}
        headerAdditionalButtons={
          <>
            {appointment && delete_appointments && !appointment.is_deleted && (
              <div className="border-r border-solid border-gray-400 pr-4 mr-4">
                <IconButton kind={IconButtonKind.default} icon={<DeleteIcon />} onClick={onAppointmentDeleteBtnClick} />
              </div>
            )}
          </>
        }
        headerBackButton={displayCalendar}
        onBack={onCloseCalendar}
        noPadding={true}
      >
        {displayCalendar ? (
          <div className="w-full">
            <div className="flex flex-wrap w-full sticky top-17 bg-white z-40">
              <div className={`flex flex-col w-full py-3 pb-3 bg-white z-40`}>
                <div className={`flex w-full border-b border-gray-400 justify-between py-8 px-6`}>
                  <div className="flex items-center  w-1/4">
                    <input
                      id="radio-manual"
                      name="manual-time"
                      value="manual"
                      type="radio"
                      checked={selected === 'manual'}
                      onChange={handleInputChange}
                      className="focus:ring-blue-600 h-4 w-4 border-2 text-blue-600 border-blue-600"
                    />
                    <label htmlFor="radio-manual" className="ml-3">
                      <span className="font-body text-base font-medium text-blue-700">Manuelle Angabe:</span>
                    </label>
                  </div>
                  <div className={`flex items-start w-3/4 ${selected === 'manual' ? ' ' : 'opacity-45'}`}>
                    <div className="flex w-1/3 rounded-md justify-items-start items-center mr-8">
                      <span className="block text-sm font-medium tracking-wide uppercase mr-4">Datum:</span>
                      <DayPickerInput
                        formatDate={formattedDateString}
                        placeholder={'DD.MM.YYYY'}
                        value={selectedDaySlotManual}
                        dayPickerProps={{
                          onDayClick: (day, { disabled }) => {
                            if (!disabled) {
                              onTimeSlotSelected(day, false)
                            }
                          },
                          disabledDays: [
                            { before: new Date().getHours() > 18 ? tomorrow : new Date() },
                            { daysOfWeek: [0, 6] },
                            { after: endDate || new Date() },
                          ],
                          firstDayOfWeek: 1,
                          months: MONTHS,
                          weekdaysLong: WEEKDAYS_LONG,
                          weekdaysShort: WEEKDAYS_SHORT,
                          locale: 'de',
                        }}
                        inputProps={{
                          onFocus: handleOnDateAndTimeFocus,
                          readOnly: true,
                          className:
                            'form-input font-body block w-full h-10 bg-gray-100 rounded-md px-4 py-2 text-base leading-6 border outline-none focus:shadow-focus focus:border-4 border-solid border-gray-400 focus:border-blue-400 sm:text-base sm:leading-5',
                        }}
                      />
                    </div>
                    <div className="flex w-1/3 rounded-md justify-items-start items-center mr-8">
                      <span className="block text-sm font-medium tracking-wide uppercase mr-4">Uhrzeit:</span>
                      <div className="rounded-md -mt-1">
                        <InputWithValidation
                          key={`${selectedDaySlotManual?.toDateString() || ''}`}
                          placeholder="HH:MM"
                          onChangeCheck={onChangeTimeCheck}
                          schema={timeSchema}
                          getErrorOrSetValue={validateAndSetTime}
                          debounceTime={500}
                          initialValue={selectedDaySlotManual ? format(selectedDaySlotManual, 'HH:mm') : undefined}
                          disabled={!selectedDaySlotManual}
                          onFocus={handleOnDateAndTimeFocus}
                        />
                      </div>
                    </div>

                    <div className={`flex w-1/3 justify-end items-center`}>
                      <div className={`flex rounded-md justify-end items-center ${selected === 'manual' ? 'block' : 'hidden'} `}>
                        <Button
                          kind={ButtonKind.primary}
                          disabled={isOccupied}
                          onClick={() => {
                            if (selectedTimeSlotManual) {
                              setSelectedTimeSlot(selectedTimeSlotManual)
                              onCloseCalendar()
                            }
                          }}
                        >
                          Zeit speichern
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={`flex w-full items-center py-5 px-8 xl:px-10 ${selected === 'slot' ? 'shadow-scrollarea' : ''}`}>
                  <input
                    id="radio-slot"
                    name="slot-time"
                    value="slot"
                    checked={selected === 'slot'}
                    onChange={handleInputChange}
                    type="radio"
                    className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                  />
                  <label htmlFor="radio-slot" className="ml-3">
                    <span className="block text-base font-body font-medium text-blue-700">Oder einen Slot wählen:</span>
                  </label>
                </div>
              </div>
            </div>
            <div className="flex flex-wrap w-full">
              <div className={`flex w-full -mt-2 pb-4 px-4 xl:px-4 ${selected === 'slot' ? '' : 'opacity-45'}`}>
                <CalendarPanelWrapper>
                  <CrmCalendar onTimeSlotClick={onTimeslotClick} disablePastSlots={true} selectedTime={selectedTimeslot} />
                </CalendarPanelWrapper>
              </div>
            </div>
          </div>
        ) : null}
        <Formik
          key="appointment-creation-form"
          initialValues={intialFormValues}
          onSubmit={onFormSubmit}
          validationSchema={AppointmentValidationScheme}
        >
          {({ values, errors, touched, handleSubmit, setFieldValue, isSubmitting, submitForm }) => (
            <Form onSubmit={handleSubmit}>
              <div className={`flex flex-wrap w-full px-4 xl:px-6 pt-2 ${displayCalendar ? 'hidden' : ''}`}>
                <div className="flex flex-wrap w-full pb-24">
                  <div className="w-1/2 p-4">
                    <div className="w-full grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                      <div className="sm:col-span-6">
                        <InputLabel>{'Standort:'}</InputLabel>
                        <div>
                          <div className="flex flex-wrap relative">
                            <TextInput disabled={true} value={activePharmacy?.name || ''} type="text" />
                          </div>
                        </div>
                      </div>
                      <div className="sm:col-span-6">
                        <InputLabel disabled={true}>{'Adresse:'}</InputLabel>
                        <div className="mt-1 rounded-md">
                          <TextInput
                            value={activePharmacy ? activePharmacy.address.address_name || '' : ''}
                            disabled={true}
                            type="text"
                            placeholder="Straße"
                          />
                        </div>
                      </div>
                      <div className="sm:col-span-3 -mt-2">
                        <div className="mt-1 rounded-md">
                          <TextInput
                            value={activePharmacy ? activePharmacy.address.zipcode || '' : ''}
                            disabled={true}
                            type="text"
                            placeholder="PLZ"
                          />
                        </div>
                      </div>

                      <div className="sm:col-span-3 -mt-2">
                        <div className="mt-1 rounded-md">
                          <TextInput value={activePharmacy ? activePharmacy.address.city || '' : ''} disabled={true} type="text" placeholder="Ort" />
                        </div>
                      </div>
                      <div className="sm:col-span-6">
                        <InputLabel disabled={true}>{'Kontakt:'}</InputLabel>
                        <div className="mt-1 rounded-md">
                          <TextInput
                            value={activePharmacy && activePharmacy.contact_person ? activePharmacy.contact_person : ''}
                            disabled={true}
                            type="text"
                            placeholder="Person"
                          />
                        </div>
                      </div>
                      <div className="sm:col-span-3 -mt-2">
                        <div className="mt-1 rounded-md">
                          <TextInput value={activePharmacy ? activePharmacy.email || '' : ''} disabled={true} type="text" placeholder="E-Mail" />
                        </div>
                      </div>

                      <div className="sm:col-span-3 -mt-2">
                        <div className="mt-1 rounded-md">
                          <TextInput value={activePharmacy ? activePharmacy.phone || '' : ''} disabled={true} type="text" placeholder="Telefon" />
                        </div>
                      </div>
                      <div className="sm:col-span-3">
                        <InputLabel disabled>{'Anrede:'}</InputLabel>
                        <div className="mt-1 rounded-md">
                          <select
                            value={values.salutation}
                            disabled={true}
                            onChange={(event: React.FormEvent<HTMLSelectElement>) => setFieldValue('salutation', event.currentTarget.value)}
                            className="w-full form-select font-body h-10 text-gray-900 bg-gray-100 block rounded-md pr-10 py-2 text-base leading-6 border outline-none focus:shadow-focus focus:border-1 border-solid border-gray-400 focus:border-blue-400"
                          >
                            <option>{'Frau'}</option>
                            <option>{'Herr'}</option>
                          </select>
                          {errors.salutation && touched.salutation ? (
                            <InputMessage kind={InputMessageKind.error}>{errors.salutation}</InputMessage>
                          ) : null}
                        </div>
                      </div>
                      <div className="sm:col-span-3">
                        <InputLabel disabled>{'Gesprächsperson:'}</InputLabel>
                        <div className="mt-1 rounded-md">
                          <TextInput
                            value={values.contactPerson}
                            onChange={(event: React.FormEvent<HTMLInputElement>) => setFieldValue('contactPerson', event.currentTarget.value)}
                            type="text"
                            disabled={true}
                            placeholder=""
                          />
                          {errors.contactPerson && touched.contactPerson ? (
                            <InputMessage kind={InputMessageKind.error}>{errors.contactPerson}</InputMessage>
                          ) : null}
                        </div>
                      </div>
                      <div className="sm:col-span-6 flex">
                        <div className="mr-2">
                          <Checkbox
                            name="sendConfirmation"
                            label="E-Mail Benachrichtigung Senden"
                            checked={values.sendConfirmation}
                            onChange={(e) => {
                              const checked = e.currentTarget.checked
                              setFieldValue('sendConfirmation', checked)
                              if (!checked) {
                                setIsCustomEmail(false)
                                setFieldValue('customEmail', '')
                              }
                            }}
                          />
                          {values.sendConfirmation && !isValidEmail(activePharmacy?.email) && !values.customEmail ? (
                            <InputMessage kind={InputMessageKind.error}>
                              {'Bitte fügen Sie eine E-Mail hinzu, um eine Terminbestätigung zu senden'}
                            </InputMessage>
                          ) : null}
                        </div>
                        {values.sendConfirmation ? (
                          <div>
                            <Checkbox
                              name="customEmail"
                              label="Benutzerdefinierte E-Mail-Adresse hinzufügen"
                              checked={isCustomEmail}
                              onChange={() => {
                                if (isCustomEmail) {
                                  setFieldValue('customEmail', '')
                                }
                                setIsCustomEmail(!isCustomEmail)
                              }}
                            />
                          </div>
                        ) : null}
                      </div>
                      {isCustomEmail && values.sendConfirmation ? (
                        <div className="sm:col-span-6">
                          <InputLabel>{'E-Mail:'}</InputLabel>
                          <div className="mt-1 rounded-md">
                            <TextInput
                              value={values.customEmail || ''}
                              onChange={(event: React.FormEvent<HTMLInputElement>) => setFieldValue('customEmail', event.currentTarget.value)}
                              type="text"
                            />
                            {errors.customEmail && touched.customEmail ? (
                              <InputMessage kind={InputMessageKind.error}>{errors.customEmail}</InputMessage>
                            ) : null}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="w-1/2 p-4">
                    <div className="w-full grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                      <div className="sm:col-span-6">
                        {!selectedTimeslot ? (
                          <React.Fragment>
                            <InputLabel>{'Zeitslot wählen:'}</InputLabel>
                            <div className="mt-1 rounded-md">
                              <Button
                                onClick={onOpenCalendar}
                                disabled={!!appointment?.date && isFuture(parseUTC(appointment.date))}
                                kind={ButtonKind.primary}
                              >
                                {'Datum und Uhrzeit festlegen'}
                              </Button>
                            </div>
                          </React.Fragment>
                        ) : (
                          <div className="mt-1 rounded-md">
                            <InputLabel>{'Gewählter Zeitslot:'}</InputLabel>
                            <div className="flex mt-1 rounded-md relative">
                              <TextInput type="text" placeholder="" value={`${formattedDateTimeString(selectedTimeslot)} ${endTimeLabel}`} />
                              <div className="flex absolute right-0 items-center h-10 mt-1 pl-1 pr-1.5 border-l border-gray-400">
                                <IconButton
                                  kind={IconButtonKind.custom}
                                  additionalCss="h-fit items-center px-0.5 py-0.5 rounded-md text-gray-700 hover:text-blue-700 focus:border-gray-300 active:text-blue-800 focus:outline-none transition ease-in-out duration-150"
                                  icon={<EditIcon />}
                                  onClick={onOpenCalendar}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                        {timeSlotEntryInvalid === true ? (
                          <InputMessage kind={InputMessageKind.error}>{'Bitte wählen Sie einen Zeitslot aus'}</InputMessage>
                        ) : null}
                      </div>
                      <div className="sm:col-span-3">
                        <InputLabel>{'Termintyp:'}</InputLabel>

                        <div className="mt-1 rounded-md">
                          <select
                            defaultValue={values.appointmentTypeId}
                            onChange={(event: React.FormEvent<HTMLSelectElement>) => setFieldValue('appointmentTypeId', event.currentTarget.value)}
                            className="w-full form-select font-body h-10 text-gray-900 bg-gray-100 block rounded-md pr-10 py-2 text-base leading-6 border outline-none focus:shadow-focus focus:border-1 border-solid border-gray-400 focus:border-blue-400"
                          >
                            {appointmentTypesQuery.isLoading ? (
                              <option>Loading...</option>
                            ) : (
                              appointmentTypesQuery.data?.appointmentTypes.map((type) => (
                                <option key={`type-${type.id}`} value={type.id}>
                                  {type.label}
                                </option>
                              ))
                            )}
                          </select>
                          {errors.appointmentTypeId && touched.appointmentTypeId ? (
                            <InputMessage kind={InputMessageKind.error}>{errors.appointmentTypeId}</InputMessage>
                          ) : null}
                        </div>
                      </div>
                      <div className="sm:col-span-3">
                        <InputLabel>{'Terminstatus:'}</InputLabel>
                        <div className="mt-1 rounded-md">
                          <select
                            defaultValue={values.statusId}
                            onChange={(event: React.FormEvent<HTMLSelectElement>) => {
                              setFieldValue('statusId', event.currentTarget.value)
                            }}
                            className="w-full form-select font-body h-10 text-gray-900 bg-gray-100 block rounded-md pr-10 py-2 text-base leading-6 border outline-none focus:shadow-focus focus:border-1 border-solid border-gray-400 focus:border-blue-400"
                            disabled={disabledChangeStatus(values)}
                          >
                            {availableStatuses.map(({ id, label, disabled }) => (
                              <option key={`status-${id}`} value={id} disabled={disabled}>
                                {label}
                              </option>
                            ))}
                          </select>
                          {errors.statusId && touched.statusId ? <InputMessage kind={InputMessageKind.error}>{errors.statusId}</InputMessage> : null}
                        </div>
                      </div>
                      {cancelledStatusIds.includes(values.statusId) && (
                        <div className="sm:col-span-6">
                          <InputLabel>Absagegrund</InputLabel>
                          <div className="mt-1 rounded-md">
                            <TextAreaInput
                              initialValue={values.cancellation_reason}
                              onChange={(event: React.FormEvent<HTMLTextAreaElement>) =>
                                setFieldValue('cancellation_reason', event.currentTarget.value)
                              }
                              placeholder=""
                            />
                            {errors.cancellation_reason && touched.cancellation_reason ? (
                              <InputMessage kind={InputMessageKind.error}>{errors.cancellation_reason}</InputMessage>
                            ) : null}
                          </div>
                        </div>
                      )}
                      <div className="sm:col-span-6">
                        <InputLabel>{'Notiz:'}</InputLabel>
                        <div className="mt-1 rounded-md">
                          <TextAreaInput
                            initialValue={values.note}
                            onChange={(event: React.FormEvent<HTMLTextAreaElement>) => setFieldValue('note', event.currentTarget.value)}
                            placeholder=""
                          />
                        </div>
                      </div>
                      <div className="sm:col-span-6">
                        <InputLabel>{'Produkte:'}</InputLabel>
                        {!selectedOrderItems?.length && Number(values.statusId) === 2 && values.appointmentTypeId === '1' && (
                          <InputMessage kind={InputMessageKind.error}>{'Bitte wählen sie ein Produkt aus'}</InputMessage>
                        )}
                      </div>
                      {getAvailableProjects().map((project) => {
                        const setItem = selectedOrderItems.find((item) => `${item.project_id}` === `${project.id}`)
                        const isDisabled =
                          !project?.is_orderable || !activePharmacy?.projects?.some((pharmacyProject) => pharmacyProject.id === project.id)
                        const fieldId = project.id.toString()
                        return (
                          <div key={`project-${project.id}`} className="sm:col-span-3 flex flex-wrap">
                            <div className="w-2/3 flex items-center">
                              <label
                                htmlFor={fieldId}
                                className={`text-base font-body ${isDisabled ? 'text-gray-500' : 'text-gray-700'} `}
                              >{`${project.name} (${project.contact_goal})`}</label>
                            </div>
                            <div className="w-1/3 pl-4">
                              <select
                                disabled={isDisabled}
                                value={setItem?.amount}
                                onChange={(event) => {
                                  updateOrderItems(parseInt(event.currentTarget.value, 10), event.currentTarget.id, project)
                                }}
                                id={fieldId}
                                className={`w-full  form-select 
                                  ${isDisabled ? 'text-gray-600 bg-gray-200 ' : 'text-gray-900 bg-gray-100 '} 
                                     font-body h-10   block rounded-md pr-10 py-2 text-base leading-6 border outline-none focus:shadow-focus focus:border-1 border-solid border-gray-400 focus:border-blue-400`}
                              >
                                {['0', '1', '2', '3', '4', '5', '6'].map((amount) => (
                                  <option key={`project-goal-${project.id}-${amount}`} id={project.id.toString()} value={amount}>
                                    {amount}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
                <div className="flex sticky bg-gradient-to-t from-white via-white to-transparent-opacity-0 self-end bottom-0 w-full justify-between p-4 pt-8 pb-6 place-items-stretch">
                  <Button kind={ButtonKind.secondary} onClick={onClose}>
                    {'Abbrechen'}
                  </Button>
                  <div className="space-x-4">
                    <Button
                      kind={ButtonKind.primary}
                      disabled={(() => {
                        if (isSubmitting) {
                          return true
                        }
                        if (values.sendConfirmation) {
                          if (!isValidEmail(activePharmacy?.email) && !isCustomEmail) {
                            return true
                          }
                        }
                        if (isCustomEmail) {
                          if (!isValidEmail(values.customEmail || '')) {
                            return true
                          }
                        }
                        if (!selectedOrderItems?.length && Number(values.statusId) === 2 && values.appointmentTypeId === '1') {
                          return true
                        }
                        return !selectedTimeslot
                      })()}
                      onClick={submitForm}
                    >
                      {appointment ? 'Änderungen speichern' : 'Termin anlegen'}
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
      <TerminationReasonModal
        isOpen={isTerminationReasonModalOpen}
        onClose={() => setTerminationReasonModalOpen(false)}
        onConfirmReason={handleTerminationReasonConfirm}
      />
    </>
  )
}

export default AppointmentModal
