import { IFilterByOption } from '@aposphaere/ui-components'

export interface IMapFilterState {
  searchTerm: string
  pharmacyName: string
  filterByOption: IFilterByOption
  selectedProjects: string[]
  selectedRevenues: string[]
  onlyWithScheduledAppointments: boolean
  includesTerminatedProjects: boolean
  isAvailableForCallcenter: boolean
  isOnlyPriority: boolean
  isOpenPotential: boolean
  isSellout: boolean
  isADPriority: boolean
}

export enum IMapFilterType {
  CLEAR,
  SET_SEARCH_TERM,
  SET_FILTER_OPTION,
  SET_SELECTED_PROJECTS,
  SET_SELECTED_REVENUES,
  SET_ONLY_WITH_SCHEDULED_APPOINTMENTS,
  SET_INCLUDES_TERMINATED_PROJECTS,
  SET_IS_AVAILABLE_FOR_CALLCENTER,
  SET_ONLY_PRIORITY,
  SET_OPEN_POTENTIAL,
  SET_ONLY_SELLOUT,
  SET_AD_PRIOTITY,
}

export interface IMapFilterAction {
  type: IMapFilterType
  payload?: Partial<IMapFilterState>
}

export const initialMapFilterState = {
  searchTerm: '',
  pharmacyName: '',
  filterByOption: IFilterByOption.OR,
  selectedProjects: [],
  selectedRevenues: [],
  onlyWithScheduledAppointments: false,
  includesTerminatedProjects: false,
  isAvailableForCallcenter: false,
  isOnlyPriority: false,
  isOpenPotential: false,
  isSellout: false,
  isADPriority: false,
}

export const filterMapReducer = (state: IMapFilterState, action: IMapFilterAction): IMapFilterState => {
  switch (action.type) {
    case IMapFilterType.CLEAR:
      return {
        ...initialMapFilterState,
      }
    case IMapFilterType.SET_SEARCH_TERM:
      return {
        ...state,
        searchTerm: action.payload?.searchTerm ?? '',
        pharmacyName: action.payload?.pharmacyName ?? '',
      }
    case IMapFilterType.SET_FILTER_OPTION:
      return {
        ...state,
        filterByOption: action.payload?.filterByOption ?? IFilterByOption.OR,
      }
    case IMapFilterType.SET_SELECTED_PROJECTS: {
      const selectedProjects = action.payload?.selectedProjects ?? []
      const updatedState = {
        ...state,
        selectedProjects,
      }
      if (!selectedProjects.length) {
        return {
          ...updatedState,
          onlyWithScheduledAppointments: false,
          includesTerminatedProjects: false,
          isAvailableForCallcenter: false,
          isOnlyPriority: false,
        }
      }
      return updatedState
    }
    case IMapFilterType.SET_SELECTED_REVENUES: {
      const selectedRevenues = action.payload?.selectedRevenues ?? []
      const updatedState = {
        ...state,
        selectedRevenues,
      }
      if (!selectedRevenues.length) {
        return {
          ...updatedState,
          onlyWithScheduledAppointments: false,
          includesTerminatedProjects: false,
          isAvailableForCallcenter: false,
          isOnlyPriority: false,
        }
      }
      return updatedState
    }
    case IMapFilterType.SET_ONLY_WITH_SCHEDULED_APPOINTMENTS:
      return {
        ...state,
        onlyWithScheduledAppointments: !state.onlyWithScheduledAppointments,
      }
    case IMapFilterType.SET_INCLUDES_TERMINATED_PROJECTS:
      return {
        ...state,
        includesTerminatedProjects: !state.includesTerminatedProjects,
      }
    case IMapFilterType.SET_IS_AVAILABLE_FOR_CALLCENTER:
      return {
        ...state,
        isAvailableForCallcenter: !state.isAvailableForCallcenter,
      }
    case IMapFilterType.SET_ONLY_PRIORITY:
      return {
        ...state,
        isOnlyPriority: !state.isOnlyPriority,
      }
    case IMapFilterType.SET_OPEN_POTENTIAL:
      return {
        ...state,
        isOpenPotential: !state.isOpenPotential,
      }
    case IMapFilterType.SET_ONLY_SELLOUT:
      return {
        ...state,
        isSellout: !state.isSellout,
      }
    case IMapFilterType.SET_AD_PRIOTITY:
      return {
        ...state,
        isADPriority: !state.isADPriority,
      }
    default:
      return { ...state }
  }
}
