import { gql } from 'graphql-request'

export const allPharmacies = gql`
  {
    pharmacies {
      id
      name
      phone
      contact_person
      email
      isPriority
      isPotential
      pharmacy_cluster {
        id
        name
      }
      address {
        address_name
        city
        zipcode
        longitude
        latitude
      }
      tasks {
        until
      }
      available_for_callcenter
      parent {
        id
      }
      pharmacy_status
      reason_for_deactivation
      additional_data
    }
  }
`

export const getPharmacy = (...parts: string[]) => gql`
  query getPharmacy($id: ID) {
    pharmacy(id: $id) {
      ${parts.join(',')}
    }
  }
`

export const getPharmacyByPharmacyCluster = gql`
  query getPharmacyByPharmacyCluster($pharmacyClusterId: String, $quarterId: ID) {
    pharmacies(pharmacy_cluster_id: $pharmacyClusterId) {
      id
      name
      okid
      phone
      aktionsapo
      isPriority
      fax
      email
      contact_person
      url
      facebook
      opening_times
      classification
      computed_label
      computed_state(quarter_id: $quarterId)
      call {
        user {
          name
        }
        created_at
      }
      appointments(quarter_id: $quarterId) {
        id
        appointmentType {
          id
          label
        }
        order_items {
          project_id
          amount
          project {
            name
            is_orderable
          }
        }
      }
      computed_label
      opening_times
      pharmacy_cluster {
        id
        name
      }
      projects {
        id
        name
        pivot {
          status_id
          is_priority
        }
      }
      notes {
        id
        title
        content
        note_type
        created_at
        user {
          name
        }
      }
      tasks {
        id
        note
        contact_person
        until
        state
        created_at
        user {
          id
          name
        }
      }
      branches {
        id
        name
        okid
        address {
          address_name
          city
          zipcode
        }
        phone
        email
      }
      address {
        address_name
        city
        zipcode
        longitude
        latitude
      }
      available_for_callcenter
      pharmacy_status
      reason_for_deactivation
      additional_data
      open_ok_request
      bayer_revenue_category{
        id
        name
      }
    }
  }
`

export const getPharmacyDetails = gql`
  query getPharmacy($id: ID, $quarterId: ID) {
    pharmacy(id: $id) {
      id
      name
      okid
      phone
      isPriority
      isPotential
      aktionsapo
      fax
      email
      contact_person
      owner
      url
      computed_state(quarter_id: $quarterId)
      call {
        user {
          name
        }
        created_at
      }
      computed_label
      facebook
      classification
      finished_presentations {
        id
        device_count
        endtime
        starttime
        time_spent
        number_of_participants
        fr_trainer_id
        project {
          id
          name
        }
      }
      address {
        address_name
        city
        zipcode
        longitude
        latitude
      }
      opening_times
      branches {
        id
        name
        okid
        address {
          address_name
          city
          zipcode
        }
        phone
        email
      }
      pharmacy_cluster {
        name
      }
      projects {
        name
        id
        pivot {
          status_id
          is_priority
        }
        is_orderable
      }
      notes {
        id
        title
        content
        note_type
        created_at
        user {
          name
        }
        created_at
      }
      tasks {
        id
        note
        contact_person
        until
        state
        user {
          id
          name
        }
        created_at
      }
      available_for_callcenter
      employees {
        id
        first_name
        last_name
        email
        is_campus_user
        completed_trainings
        zipcode
        pharmacy {
          id
          okid
        }
      }
      campus_registration_code
      parent {
        id
        name
        okid
        address {
          address_name
          city
          zipcode
        }
      }
      activity_log {
        user_name
        model
        event
        timestamp
      }
      pharmacy_status
      reason_for_deactivation
      additional_data
      open_ok_request
      bayer_revenue_category{
        id
        name
      }
    }
  }
`

export const getPharmacyEmployees = gql`
  query getPharmacy($id: ID) {
    pharmacy(id: $id) {
      id
      name
      employees {
        id
        first_name
        last_name
        email
        is_campus_user
        completed_trainings
        zipcode
        pharmacy {
          id
          okid
        }
      }
    }
  }
`

export const getPharmacyEmployeePositions = gql`
  {
    employeePositions {
      id
      name
    }
  }
`

export const getPharmacySearchResults = gql`
  query searchPharmacy($search: String) {
    searchPharmacy(search: $search) {
      id
      name
      okid
    }
  }
`
